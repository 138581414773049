
export default class UtilsHelper {

  public static escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public static nl2br(str: string): string {
    return str.replace(/\n/g, '<br />');
  }

}
